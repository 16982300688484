<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">标题:</span>
        <el-input class="width_200" clearable v-model="search.title" placeholder="请输入标题"></el-input>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" @selectionChange="selectChange" :header-cell-style="{ background: '#F7F8FA' }">
      <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="username" label="账号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="src" label="模板" align="center" :show-overflow-tooltip="true">
        <template v-slot="scope">
          <ximg :src="scope.row.src" :width="50" :height="50" :enlarge="true" alt=""></ximg>
        </template>
      </el-table-column>
<!--      <el-table-column prop="face" label="脸图" align="center" :show-overflow-tooltip="true">-->
<!--        <template v-slot="scope">-->
<!--          <ximg :src="scope.row.face" :width="30" :height="30" :enlarge="true" alt=""></ximg>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column prop="dst" label="生成图" align="center" :show-overflow-tooltip="true">-->
<!--        <template v-slot="scope">-->
<!--          <ximg v-if="scope.row.dst" :src="scope.row.dst" :width="30" :height="30" :enlarge="true" alt=""></ximg>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column prop="addtime" label="添加时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="enable" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div v-html="getStatus(scope.row)"></div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150px" align="center">
        <template #default="scope">
          <span class="operation" @click="showInfo(scope.row)">查看</span>
          <span class="operation" v-if="scope.row.status == 3" @click="refund(scope.row)">退款</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />
  </div>

  <el-dialog title="图片换脸查看" width="1000px" v-model="dialog">
    <el-row>
      <el-col  :span="8">
        <div>原图</div>
        <ximg :src="row.src" :width="200" :height="200" :enlarge="true" alt=""></ximg></el-col>
      <el-col :span="8">
        <div>脸图</div>
        <ximg :src="row.face" :width="200" :height="200" :enlarge="true" alt=""></ximg>
      </el-col>
      <el-col :span="8">
        <div>合成图</div>
        <ximg :src="row.dst" :width="200" :height="200" :enlarge="true" alt=""></ximg>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">备注</el-col>
      <el-col :span="20">{{row.reson}}</el-col>
    </el-row>
  </el-dialog>

</template>

<script>
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "ai_ty_log",
  components: {
    page,
  },
  data() {
    return {
      pager:{total: 0, page: 1, rows: 10},
      search:{
        title:"",
        type:0,
      },
      tableData:[],
      selectRows:[],
      statusList:["队列中","处理中","成功","失败","已退款"],
      row:{},
      dialog:false,
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    refund(row){
      this.$confirm("确定退款？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=>{
        httpClient("ai_image_refund").post({id:row.id}).then((res)=>{
          if (res.code == 0){
            row.status = 4
            return this.$message({message:"退款成功",type:"success"})
          }else{
            return this.$message({message:res.msg,type:"error"})
          }
        })
      })
    },
    showInfo(row){
      this.title = `用户:[${row.username}] 模板:[${row.title}]`
      this.row = row
      this.dialog = true
    },
    getStatus(row){
      var label = this.statusList[row.status]
      var color = ""
      if (row.status == 2){
        color = "green"
      }else if (row.status == 3 || row.status == 4){
        color = "red"
      }else if (row.status == 1){
        color = "gray"
      }
      return `<span style="color:${color}">${label}</span>`
    },
    getList(){
      var params = {
        pageid:this.pager.page-1,
        pcount:this.pager.rows,
      }
      params = {...params,...this.search}
      httpClient("ai_image_log").post(params).then((res)=>{
        if (res.code == 0){
          this.tableData = res.data.list
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
  }
};
</script>

<style>

/* 图片上传部分 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px;
  height: 300px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 300px;
  height: 300px;
  display: block;
}
.el-row{margin-bottom: 10px;}
.hot_item .el-badge__content{
  margin-top:10px; margin-right:-10px; color:#FFFFFF; background: red;
}
</style>